export default {
  chooseWallbox: 'Wählen Sie eine Ladebox aus:',
  chooseAccessories: 'Folgendes Zubehör steht Ihnen noch zur Verfügung:',
  client: {
    formFields: {
      clientType: {
        options: {
          business: {
            label: 'Geschäftskunde',
          },
          private: {
            label: 'Privatkunde',
          },
        },
      },
    },
    label:
      'Nutzen Sie einfach unseren Bestellasisstenten und bestellen Sie Ihren Pre-Check oder Ihre Ladebox für zuhause:',
    title: 'Kundenart',
  },
  configuration: {
    formFields: {
      chargingPoints: {
        helperText:
          'Wenn Sie mehr als ein E-Auto gleichzeitig laden möchten, wäre eine Ladebox mit zwei Ladepunkten von Vorteil.',
        label: 'Benötigen Sie einen oder zwei Ladepunkte?',
        options: {
          one: {
            label: 'Ein Ladepunkt',
          },
          two: {
            label: 'Zwei Ladepunkte',
          },
        },
        outputLabel: 'Anzahl der Ladepunkte',
      },
      authorization: {
        helperText:
          'Wählen Sie diese Option, wenn Ihr Stellplatz frei zugänglich ist und unbefugte Personen Ihre Wallbox nutzen könnten.',
        label: 'Soll für den Ladevorgang eine Autorisierung erforderlich sein?',
        options: {
          no: {
            label: 'Nein',
          },
          yes: {
            label: 'Ja',
          },
        },
        outputLabel: 'Funktionsweise',
      },
      chargingPower: {
        label: 'Wie schnell möchten Sie Ihr Fahrzeug laden?',
        options: {
          '11': {
            description:
              'Sie können Ihr Elektrofahrzeug mit einer Reichweite von ca. 70 km pro Stunde laden.',
            label: '11 kW',
          },
          '22': {
            description:
              'Sie können Ihr Elektrofahrzeug mit einer Reichweite von ca. 140 km pro Stunde Laden.',
            label: '22 kW',
          },
          '3,7': {
            // description:
            //   'Sie können Ihr Elektrofahrzeug mit einer Reichweite von ca. 25 km pro Stunde laden.',
            label: '3,7 kW',
          },
        },
        outputLabel: 'Ladeleistung',
      },
      connectionType: {
        label:
          'Wünschen Sie eine Ladestation mit Steckdose oder angeschlagenem Kabel?',
        options: {
          cable: {
            description:
              'Ladestation mit Typ 2 Stecker. Es ist kein zusätzliches Kabel zum Laden notwendig.',
            label: 'Angeschlossenes Kabel',
          },
          socket: {
            description:
              'Ladestation mit Typ 2 Dose. Beachten Sie, dass bei Auswahl einer Ladestation mit Steckdose ein zusätzliches Kabel zum Laden notwendig ist.',
            label: 'Steckdose',
          },
        },
        outputLabel: 'Anschluss',
      },
      paid: {
        helperText:
          'Möchten Sie Ihr Firmenfahrzeug privat laden oder muss die Wallbox aus anderen Gründen abrechnungsfähig sein? Fragen Sie am besten Ihren Steuerberater ob Ihre Ladebox abrechnungsfähig sein muss oder ob eine einfache Aufstellung der geladenen kW ausreichend ist.',
        label: 'Soll die Ladestation abrechungsfähig sein?',
        options: {
          no: {
            label: 'Nein',
          },
          yes: {
            label: 'Ja',
          },
        },
        outputLabel: 'Abrechnungsfähigkeit',
      },
      smartphoneConnection: {
        helperText:
          'Sie können den Ladevorgang mit einer App kompatiblen Wallbox bequem von jedem Ort aus verwalten.',
        label:
          'Wünschen Sie eine Verwaltung der Ladevorgänge über PC oder Smartphone?',
        options: {
          no: {
            label: 'Nein',
          },
          yes: {
            label: 'Ja',
          },
        },
        outputLabel: 'PC/Smartphone-Verwaltung',
      },
    },
    label: '',
    title: 'Kriterien',
  },
  contact: {
    consultationLabel: 'Kontaktdaten für eine Beratung',
    deliveryInformation:
      'Der Versand erfolgt innerhalb der nächsten 14 Tage; vorbehaltlich der Verfügbarkeit des Herstellers. Ihre Rechnung erhalten Sie zusammen mit Ihrer Ladebox. Diese können Sie bequem innerhalb von 10 Tagen nach Erhalt der Ware begleichen.',
    formFields: {
      city: {
        label: 'Stadt',
      },
      email: {
        label: 'E-Mail',
      },
      firstname: {
        label: 'Vorname',
      },
      gender: {
        label: 'Anrede',
        options: {
          divers: {
            label: 'Divers, keine Angabe',
          },
          female: {
            label: 'Frau',
          },
          male: {
            label: 'Herr',
          },
        },
      },
      invoiceCity: {
        label: 'Stadt',
      },
      invoiceFirstname: {
        label: 'Vorname',
      },
      invoiceGender: {
        label: 'Anrede',
        options: {
          female: {
            label: 'Frau',
          },
          male: {
            label: 'Herr',
          },
        },
      },
      invoiceLastname: {
        label: 'Nachname',
      },
      invoiceStreetAddress: {
        label: 'Strasse und Hausnummer',
      },
      invoiceZipcode: {
        label: 'Postleitzahl',
      },
      lastname: {
        label: 'Nachname',
      },
      message: {
        label: 'Möchten Sie uns noch etwas mitteilen?',
      },
      phone: {
        label: 'Telefonnummer',
      },
      streetAddress: {
        label: 'Strasse und Hausnummer',
      },
      zipcode: {
        label: 'Postleitzahl',
      },
    },
    invalidSituationWarning:
      'Leider ist die Installation einer Wallbox ohne eigenen Stellplatz nicht möglich. Wir beraten Sie aber gerne zu allen Fragen der Elektromobilität.',
    label: 'Kontaktdaten',
    privacy:
      'Die im Zusammenhang mit der Bestellung erhobenen Daten werden von der WVV automatisiert gespeichert und verarbeitet und im Rahmen der Bestellung verwendet.',
    sameInvoiceAddress: 'Lieferadresse stimmt mit Rechnungsadresse überein',
    title: 'Kontakt',
  },
  customer: {
    formFields: {
      alreadyCustomer: {
        options: {
          no: {
            label: 'Nein',
          },
          yes: {
            label: 'Ja',
          },
        },
      },
    },
    label: 'Sind Sie bereits Strom- oder Gaskunde bei der WVV-Energie?',
    title: 'Bestandskunde',
  },
  done: {
    title: 'Fertig',
  },
  interest: {
    formFields: {
      interest: {
        options: {
          installation: {
            label: 'Ladebox + Installation',
          },
          wallbox: {
            label: 'Ladebox',
          },
        },
      },
    },
    label: 'Für welches Produkt interessieren Sie sich?',
    installationHint:
      '*Aus organisatorischen Gründen kann die Installation nur im Umkreis von 50km um Würzburg stattfinden. Die Kosten für die Installation werden wir Ihnen nach einem Vor-Ort Termin gesondert mitteilen, da es immer auf die Gegebenheiten vor Ort ankommt.',
    installationPriceHint: '(Inkl. 19% Ust.)',
    title: 'Interesse',
  },
  noWallboxesFound:
    'Es konnten keine Ladeboxen für die ausgewählten Kriterien gefunden werden. Klicken Sie auf „Kriterien ändern“ um Ihre Kriterien zu anzupassen oder auf „Weiter“ um eine persönliche Beratung zu erhalten.',
  overview: {
    formFields: {
      agb: {
        helperText:
          'Hinweis Installationskosten: Wenn Sie eine Installation, beauftragt haben, werden wir Ihnen, die Installationskosten gesondert nach einem mit Ihnen vereinbarten Vor-Ort Termin mitteilen. Wir werden uns zeitnah bzgl. eines Termins bei Ihnen melden.',
        label: 'AGB',
      },
    },
    incudedTax: 'Enthaltene USt. (19%)',
    label: 'Übersicht Ihrer Eingaben',
    shippingCost: 'Versandkosten',
    submitOrder: 'Zahlungspflichtig bestellen',
    title: 'Übersicht',
    total: 'Gesamtsumme',
    totalNet: 'Gesamtsumme (netto)',
  },
  situation: {
    formFields: {
      situation: {
        options: {
          none: {
            description:
              'Wenn Sie über keinen Stellplatz verfügen, ist die Installation einer Ladestation nicht möglich und Sie sind auf das öffentliche Laden angewiesen. Informieren Sie sich bei Ihrem Arbeitgeber, möglicherweise befindet sich an Ihrem Arbeitsplatz eine Lademöglichkeit, die Sie nutzen können.',
            label: 'Ohne Stellplatz',
          },
          own: {
            description:
              'Falls Sie über einen eigenen Stellplatz, eine Garage, einen Carport oder über einen Außenbereich auf Ihrem Grundstück verfügen, können Sie frei über die Installation einer Ladeinfrastruktur entscheiden.',
            label: 'Eigener Stellplatz',
          },
          rented: {
            description:
              'Hier bedarf es bei der Installation einer Ladeinfrastruktur einer vorherigen Abstimmung des jeweiligen Vermieters bzw. der Wohnungseigentümergemeinschaft (WEG). Sprechen Sie auch mit Mitmietern, um etwaige Kostenteilungen zu nutzen, falls mehrere Ladestationen gleichzeitig errichtet werden sollen.',
            label: 'Angemieteter Stellplatz',
          },
        },
      },
    },
    label: 'Wie sieht bei Ihnen die Stellplatz-Situation aus?',
    title: 'Stellplatz',
  },
  start: {
    buttonText: 'Starten',
    description: '',
    label: '',
  },
  wallboxes: {
    consultationNecessary: 'Beratung erforderlich',
    formFields: {
      amount: {
        label: 'Wie viele Ladeboxen möchten Sie bestellen?',
        outputLabel: 'Anzahl der Ladeboxen',
      },
      wallbox: {
        options: {},
        outputLabel: 'Ladebox',
      },
      accessory: {
        outputLabel: 'Zubehör',
        options: {
          'cable-type2-10m': {
            description: '275,00 €',
            label: 'Ladekabel Typ 2 Stecker 10 meter',
          },
          'cable-type2-7m': {
            description: '246,00 €',
            label: 'Ladekabel Typ 2 Stecker 7 meter',
          },
          'base-alfen-single': {
            description: '227,00 €',
            label: 'Standfuß Alfen Single',
          },
          'base-alfen-double': {
            description: '333,00 €',
            label: 'Standfuß Alfen Double',
          },
          'base-alfen-grounding': {
            description: '357,00 €',
            label: 'Fundament Alfen Double',
          },
          contact: 'Ich bin mir unsicher, bitte kontaktieren Sie mich nochmal.',
          consultation: {
            description: '',
            label: 'ich bin mir unsicher, bitte kontaktieren Sie mich nochmal',
          },
        },
      },
    },
    label: 'Wählen Sie eine Ladebox aus:',
    select: 'Auswählen',
    selected: 'Ausgewählt',
    title: 'Ladebox',
    wallboxNoCablesHint:
      '<span class="gc-underline">Hinweis:</span> Bitte beachten Sie, diese Wallbox hat keine integrierten Kabel. Falls Sie kein Ladekabel besitzen, können Sie hier unter "Zubehör ein Ladekabel bestellen.',
    wallboxPriceHint: '(Gesamtpreis inkl. 19% Ust.)',
    wallboxShippingHint:
      '*Außerhalb des MFN-Netzgebietes fallen Versandkosten in Höhe von 20,- € an. Bitte beachten Sie <a class= "gc-underline" target="_blank" href="https://www.mainfrankennetze.de/media/mfn_inhalte/hauptnavigation_1/netzinfos/strom/netzgebiet_1/netzgebiet_strom.pdf">diese</a> PDF. Standardlieferzeit 14 Tage –  vorbehaltlich der Verfügbarkeit des Herstellers.',
  },
};
