import axios from 'axios';
import { AssistantContext } from '../../config/context';
import { StepDefinition } from '../../interfaces/step-definition';
import { getMailBody } from '../formatter/context';
import { getFilesFromContext } from '../xstate/machine';

export async function uploadFile(file: File): Promise<string> {
  const filePath = Date.now() + '/' + file.name;
  try {
    const { data: signedUploadUrl } = await axios.post(
      process.env.functionsUrl + '/signedUploadUrl',
      {
        filePath,
        fileType: file.type,
      },
    );

    await axios.put(signedUploadUrl, file, {
      headers: {
        'Content-Type': file.type,
      },
    });

    return filePath;
  } catch (e) {
    console.error(e);
    throw new Error(
      `Es ist ein Fehler Hochladen der Datei '${file.name}' aufgetreten.`,
    );
  }
}

export async function getMailAttachments(
  context: AssistantContext,
): Promise<string[]> {
  const files = await Promise.all(
    getFilesFromContext(context).map(async file => {
      return await uploadFile(file);
    }),
  );
  return files;
}

export async function sendMail(
  steps: StepDefinition[],
  context: AssistantContext,
  orderContext?: any,
): Promise<void> {
  const mailBody = getMailBody(steps, context);
  const mailAttachments = await getMailAttachments(context);

  try {
    await axios.post(process.env.VUE_APP_FUNCTIONS_URL + '/mail', {
      attachments: mailAttachments,
      context,
      data: mailBody,
      ...orderContext,
    });
  } catch (e) {
    console.error(e);
    throw new Error('Es ist ein Fehler beim Versenden der Mail aufgetreten.');
  }
}
