import customer from '../steps/customer';
import client from '../steps/client';
import interest from '../steps/interest';
import situation from '../steps/situation';
import configuration from '../steps/configuration';
import wallboxes from '../steps/wallboxes';
import contact from '../steps/contact';
import overview from '../steps/overview';
import done from '../steps/done';

const steps = [
  customer,
  client,
  interest,
  situation,
  configuration,
  wallboxes,
  contact,
  overview,
  done,
];

export default steps;
