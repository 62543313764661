






































































































import { WallboxOffer, useLisOfferData } from '../lib/data/wallboxes';
import { formatPrice } from '../lib/formatter/price';
import GcImage from './primitives/GcImage.vue';
import { Ref, computed, inject, ref } from '@vue/composition-api';
import GcButton from './primitives/GcButton.vue';
import { VInput } from 'vuetify/lib';

export default VInput.extend({
  components: {
    GcButton,
    GcImage,
  },
  props: {
    isCustomer: {
      required: true,
      type: Boolean,
    },
  },
  setup(props: any, { emit }) {
    const expanded = ref(false);
    const { wallboxOffers } = useLisOfferData();
    const wallboxFilter = inject<
      Ref<{
        smartphoneConnection: Array<string>;
        paid: Array<string>;
        chargingPower: Array<string>;
        chargingPoints: Array<string>;
        connectionType: Array<string>;
        authorization: Array<string>;
      }>
    >('wallboxFilter');

    const connectionTypeMapping: Record<string, string> = {
      cable: 'Typ-2-Stecker',
      socket: 'Typ-2-Dose',
    };

    const filteredWallboxes = computed(() => {
      const filter = wallboxFilter?.value;

      return (wallboxOffers.value || []).filter(wallbox => {
        const criteria: boolean[] = [];

        if (
          filter?.smartphoneConnection &&
          Array.isArray(filter?.smartphoneConnection) &&
          filter.smartphoneConnection.length > 0
        ) {
          criteria.push(
            filter.smartphoneConnection
              ? wallbox._reference.hasApp ===
                  (filter.smartphoneConnection[0] === 'yes')
              : true,
          );
        }

        if (
          filter?.paid &&
          Array.isArray(filter?.paid) &&
          filter.paid.length > 0
        ) {
          criteria.push(wallbox._reference.ocpp === (filter.paid[0] === 'yes'));
        }

        if (
          filter?.connectionType &&
          Array.isArray(filter?.connectionType) &&
          filter.connectionType.length > 0
        ) {
          criteria.push(
            wallbox._reference.connectors.includes(
              connectionTypeMapping[filter.connectionType[0]],
            ),
          );
        }

        if (
          filter?.chargingPower &&
          Array.isArray(filter?.chargingPower) &&
          filter?.chargingPower.length > 0
        ) {
          criteria.push(
            wallbox._reference.chargingPower >=
              parseFloat(filter.chargingPower[0]),
          );
        }

        if (
          filter?.chargingPoints &&
          Array.isArray(filter?.chargingPoints) &&
          filter?.chargingPoints.length > 0
        ) {
          if (filter.chargingPoints.includes('one')) {
            criteria.push(wallbox._reference.chargingPoints === 1);
          }
          if (filter.chargingPoints.includes('two')) {
            criteria.push(wallbox._reference.chargingPoints === 2);
          }
        }

        if (
          filter?.authorization &&
          Array.isArray(filter?.authorization) &&
          filter?.authorization.length > 0
        ) {
          criteria.push(
            filter.authorization.includes('yes') ===
              wallbox._reference.authorization.hasRFID,
          );
        }

        return !criteria.includes(false);
      });
    });

    function isSelectedWallbox(wallbox: WallboxOffer) {
      return props.value && props.value === wallbox._id;
    }

    function toggleHighlights() {
      expanded.value = !expanded.value;
    }

    function selectWallbox(wallbox: WallboxOffer) {
      emit(
        'input',
        props.value && props.value === wallbox._id ? '' : wallbox._id,
      );
    }

    function getWallboxPrice(wallbox: WallboxOffer) {
      if (props.isCustomer && wallbox.priceReduced) {
        return formatPrice(wallbox.priceReduced);
      }

      return formatPrice(wallbox.price);
    }

    return {
      expanded,
      filteredWallboxes,
      gcBackendS3Url: process.env.VUE_APP_GC_BACKEND_S3_URL,
      getWallboxPrice,
      isSelectedWallbox,
      selectWallbox,
      toggleHighlights,
      wallboxOffers,
    };
  },
});
