import { Ref, ref } from '@vue/composition-api';
import wallboxes from '../../static-data/wallboxes.json';

export interface WallboxOffer {
  _id: string;
  isBusinessCustomer: boolean;
  isPrivateCustomer: boolean;
  price: number;
  priceReduced: number;
  _reference: {
    _id: string;
    annotation?: string;
    authorization: {
      exists: boolean;
      hasApp: boolean;
      hasExternal: boolean;
      hasKey: boolean;
      hasRFID: boolean;
      hasSMS: boolean;
    };
    chargingPoints: number | null;
    chargingPower: number;
    connectors: Array<string>;
    electricMeter: {
      exists: boolean;
      hasHSM: boolean;
      hasTransformer: boolean;
      isMIDCertified: boolean;
    };
    hasApp: boolean;
    hasLoadManagement: boolean;
    hasRCD: boolean;
    hasWebpanel: boolean;
    image?: string;
    installation: string;
    manufacturer: string;
    name: string;
    ocpp: boolean;
    protectionType?: string;
    retailerDetailUrl?: string;
    rpp: number | null;
    hint?: string;
  };
}

const wallboxOffers = ref<null | Array<WallboxOffer>>(null);
const loading = ref(false);

function loadWallboxOffer(): void {
  wallboxOffers.value = wallboxes;
}

export function useLisOfferData(): {
  wallboxOffers: Ref<null | Array<WallboxOffer>>;
  loading: Ref<boolean>;
} {
  if (wallboxOffers.value === null && !loading.value) {
    loadWallboxOffer();
  }

  return {
    loading,
    wallboxOffers,
  };
}
