const mfnGridArea = [
  '97084',
  '97082',
  '97080',
  '97078',
  '97076',
  '97074',
  '97072',
  '97070',
  '97246',
  '97218',
  '97204',
  '97236',
  '97299',
  '97249',
  '97295',
  '97270',
  '97286',
  '97286',
  '97261',
  '97271',
  '97276',
  '97222',
  '97230',
  '97228',
  '97234',
  '97256',
  '97265',
  '97288',
  '97274',
];

export const isInMfnGridArea = (postalCode: string) => mfnGridArea.includes(postalCode);
