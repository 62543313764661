import { Ref, ref } from '@vue/composition-api';
import accessories from '../../static-data/accessories.json';

export interface AccessoryOffer {
  _id: string;
  price?: number;
  display: boolean;
  _reference: {
    manufacturer?: string;
    chargingPoints?: number | null;
    name: string;
  };
}

const accessoryOffers = ref<null | Array<AccessoryOffer>>(null);
const loading = ref(false);

function loadAccessoryOffer(): void {
  accessoryOffers.value = accessories;
}

export function useLisOfferData(): {
  accessoryOffers: Ref<null | Array<AccessoryOffer>>;
  loading: Ref<boolean>;
} {
  if (accessoryOffers.value === null && !loading.value) {
    loadAccessoryOffer();
  }

  return {
    accessoryOffers,
    loading,
  };
}
