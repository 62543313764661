






























import { VInput } from 'vuetify/lib';
import GcButton from './GcButton.vue';
import GcTextField from './GcTextField.vue';

export default VInput.extend({
  components: { GcButton, GcTextField },
  props: {
    max: {
      default: 100,
      type: Number,
    },
    min: {
      default: 0,
      type: Number,
    },
  },
  setup(props, { emit }) {
    const onInput = (value: string) => {
      const max = props.max as number;
      const min = props.min as number;
      if (parseInt(value, 10) > max) {
        emit('input', String(max));
        return;
      }

      if (parseInt(value, 10) < min) {
        emit('input', String(min));
        return;
      }

      emit('input', String(value));
    };

    const onClear = () => {
      emit('input', '');
    };

    function plus() {
      const currentValue = parseInt(props.value as string, 10);
      emit('input', String(currentValue + 1));
    }

    function minus() {
      const currentValue = parseInt(props.value as string, 10);
      emit('input', String(currentValue > 0 ? currentValue - 1 : 0));
    }

    return { minus, onClear, onInput, plus };
  },
});
