export enum StepId {
  DONE = 'done',
  CUSTOMER = 'customer',
  CLIENT = 'client',
  SITUATION = 'situation',
  INTEREST = 'interest',
  CONFIGURATION = 'configuration',
  WALLBOXES = 'wallboxes',
  CONTACT = 'contact',
  OVERVIEW = 'overview',
  CHARGING_STATION = 'chargingStation',
}

export enum StepGroupId {
  LOCATIONS = 'locations',
}
