import { StepId } from '../../consts/assistant-steps';
import { FormFieldType } from '../../consts/input-types';
import { StepDefinition } from '../../interfaces/step-definition';
import component from './Interest.vue';
import { isInMfnGridArea } from '../../lib/data/location';
import i18n from '../../plugins/i18n';

const config: StepDefinition = {
  component,
  fields: [
    {
      config: {
        columns: 2,
        disableUnselect: true,
        options: [
          {
            icon: 'charging-station',
            value: 'wallbox',
          },
          {
            icon: ['charging-station', 'wrench'],
            value: 'installation',
          },
        ],
        singleAnswer: true,
        verticalOptionLayout: true,
      },
      name: 'interest',
      required: true,
      type: FormFieldType.MultipleChoice,
    },
  ],
  id: StepId.INTEREST,
  outputFormatter: (stepContext, context) => {
    let installationAvailable = true;

    if (context) {
      const zipcode = context[StepId.CONTACT].zipcode;
      installationAvailable = isInMfnGridArea(zipcode);
    }

    let output = `${i18n.t(
      `${StepId.INTEREST}.formFields.interest.options.${stepContext.interest[0]}.label`,
    )}`;
    output = output.replace(/\*+$/, '');

    if (stepContext.interest[0] === 'installation' && !installationAvailable) {
      output += `*\n${i18n.t(`${StepId.INTEREST}.installationHint`)}`;
    }

    return output;
  },
};

export default config;
