import { StepId } from '../../consts/assistant-steps';
import { FormFieldType } from '../../consts/input-types';
import { StepDefinition } from '../../interfaces/step-definition';
import component from './Overview.vue';

const config: StepDefinition = {
  component,
  fields: [
    {
      name: 'agb',
      required: true,
      type: FormFieldType.Checkbox,
    },
  ],
  id: StepId.OVERVIEW,
  outputFormatter: (stepContext: any) => {
    const output = `**AGB:** ${stepContext.agb ? 'Ja' : 'Nein'}`;

    return output;
  },
};

export default config;
