






import { computed, defineComponent } from '@vue/composition-api';
import GcAlert from '../../components/primitives/GcAlert.vue';
import { stepComponentProps } from '../../lib/steps/helper';
import { StepId } from '../../consts/assistant-steps';

export default defineComponent({
  components: {
    GcAlert,
  },
  props: {
    ...stepComponentProps,
  },
  setup(props) {
    const isOrder = computed(() => {
      return props.assistantContext[StepId.OVERVIEW].agb;
    });

    return {
      isOrder,
    };
  }
});
