











































import { defineComponent } from '@vue/composition-api';
import GcButton from './primitives/GcButton.vue';
import GcCard from './primitives/GcCard.vue';
import GcDivider from './primitives/GcDivider.vue';

export default defineComponent({
  components: { GcButton, GcCard, GcDivider },
  props: {
    showEdit: {
      default: false,
      type: Boolean,
    },
    showRemove: {
      default: false,
      type: Boolean,
    },
    title: {
      default: '',
      type: String,
    },
  },
  setup(_, { emit }) {
    function onClickEdit() {
      emit('edit');
    }

    function onClickRemove() {
      emit('remove');
    }

    return {
      onClickEdit,
      onClickRemove,
    };
  },
});
