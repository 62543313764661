import { StepId } from '../../consts/assistant-steps';
import { FormFieldType } from '../../consts/input-types';
import { StepDefinition } from '../../interfaces/step-definition';
import component from './Customer.vue';

const config: StepDefinition = {
  component,
  fields: [
    {
      config: {
        columns: 2,
        disableUnselect: true,
        options: [
          {
            icon: 'check',
            value: 'yes',
          },
          {
            icon: 'times',
            value: 'no',
          },
        ],
        singleAnswer: true,
        verticalOptionLayout: true,
      },
      name: 'alreadyCustomer',
      outputFormatter: 'value',
      required: true,
      type: FormFieldType.MultipleChoice,
    },
  ],
  id: StepId.CUSTOMER,
};

export default config;
