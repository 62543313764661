









































import {
  AccessoryOffer,
  useLisOfferData as useLisOfferAccessoryData,
} from '../lib/data/accessories';
import i18n from '../plugins/i18n';
import { useLisOfferData as useLisOfferWallboxData } from '../lib/data/wallboxes';
import { useResponsiveBreakpoints } from '../lib/ui-helper/responsive';
import { formatPrice } from '../lib/formatter/price';
import { watch, ref } from '@vue/composition-api';
import { VInput } from 'vuetify/lib';
import GcRow from './primitives/GcRow.vue';
import GcCol from './primitives/GcCol.vue';

export const CONTACT_OPTION_ID = '9999999999999999999' as string;

export default VInput.extend({
  components: {
    GcRow,
    GcCol,
  },
  props: {
    columns: {
      default: 2,
      type: Number,
    },
    filter: {},
  },
  setup(props: any, { emit }) {
    const { isMd } = useResponsiveBreakpoints();
    const selected = ref<AccessoryOffer[]>([]);
    if (props.value) {
      selected.value = props.value;
    }
    const { accessoryOffers } = useLisOfferAccessoryData();
    const { wallboxOffers } = useLisOfferWallboxData();
    const contactOption = {
      _id: CONTACT_OPTION_ID,
      _reference: {
        name: i18n.t(
          'wallboxes.formFields.accessory.options.contact',
        ) as string,
      },
      display: true,
    };
    let defaultAccessories =
      accessoryOffers?.value?.filter(el => el.display === true) || [];
    defaultAccessories = [...defaultAccessories, contactOption];
    const accessories = ref(defaultAccessories);
    const sortAccessories = (a, b) => {
      if (a._id < b._id) {
        return -1;
      } else if (a._id > b._id) {
        return 1;
      }
      return 0;
    };

    function setAvailableAccessories(val: any) {
      if (!wallboxOffers?.value || wallboxOffers?.value?.length <= 0) {
        return;
      }

      if (val.wallbox) {
        const selectedWallbox = wallboxOffers.value.filter(
          wallbox => wallbox._id === val.wallbox,
        )[0];

        if (selectedWallbox && accessoryOffers.value) {
          const manufacturer = selectedWallbox?._reference?.manufacturer;
          const filtered = accessoryOffers.value.filter(
            (accessory: AccessoryOffer) => {
              const filterManufacturer =
                accessory._reference.manufacturer === manufacturer;
              const filterChargingPoints =
                !accessory._reference.chargingPoints ||
                accessory._reference.chargingPoints ===
                  selectedWallbox?._reference?.chargingPoints;

              if (filterManufacturer && filterChargingPoints) {
                return true;
              }
            },
          );

          accessories.value = [...defaultAccessories, ...filtered].sort(
            sortAccessories,
          );
        }
      } else {
        /**
         * reset accessories on deselect wallbox
         */
        accessories.value = defaultAccessories;
      }
    }

    /**
     *
     * @param accessory
     */
    function isSelectedAccessory(accessory: AccessoryOffer) {
      return (
        selected.value.findIndex(
          (e: AccessoryOffer) => e._id === accessory._id,
        ) >= 0
      );
    }

    /**
     *
     * @param accessory
     */
    function selectAccessory(accessory: AccessoryOffer) {
      const i = selected.value.findIndex(
        (e: AccessoryOffer) => e._id === accessory._id,
      );

      if (i < 0) {
        selected.value.push(accessory);

        if (accessory._id === CONTACT_OPTION_ID) {
          selected.value = [accessory];
        }
      } else {
        selected.value.splice(i, 1);
      }

      emit('input', selected.value);
    }

    /**
     *
     * @param accessory
     */
    function getAccessoryPrice(accessory: AccessoryOffer) {
      if (accessory.price) {
        return formatPrice(accessory.price);
      }
      return '';
    }

    watch(props.filter, (val, oldVal) => {
      setAvailableAccessories(val);
    });

    setAvailableAccessories(props.filter);

    return {
      accessories,
      getAccessoryPrice,
      isSelectedAccessory,
      selectAccessory,
      isMd,
    };
  },
});
