import { AssistantContext } from '../../config/context';
import { StepGroupId, StepId } from '../../consts/assistant-steps';
import { FormFieldType } from '../../consts/input-types';
import {
  FormField,
  FormMultipleChoiceConfig,
  StepDefinition,
} from '../../interfaces/step-definition';
import i18n from '../../plugins/i18n';
import { getStepContext } from '../context';
import { getStep } from '../steps/helper';

function getStepTitle(stepId: StepId | string) {
  return i18n.t(`${stepId}.title`);
}

function getStepFieldLabel(stepId: StepId | string, fieldName: string) {
  const fieldPath = `${stepId}.formFields.${fieldName}`;
  return i18n.te(`${fieldPath}.outputLabel`)
    ? i18n.t(`${fieldPath}.outputLabel`)
    : i18n.t(`${fieldPath}.label`);
}

function getFormattedTextFieldValue(fieldValue: any): string {
  return ((fieldValue as string) || '').trim();
}

function getFormattedMultipleChoiceValue(
  field: FormField,
  fieldValue: string[],
  step: StepDefinition,
): string {
  return fieldValue
    .map(optionValue => {
      if (
        (field.config as FormMultipleChoiceConfig).options
          .map(o => o.value)
          .includes(optionValue)
      ) {
        return i18n.t(
          `${step.id}.formFields.${field.name}.options.${optionValue}.label`,
        );
      }
      return optionValue;
    })
    .join(', ');
}

function getFormattedSwitchValue(fieldValue: any): string {
  return fieldValue
    ? i18n.t('switch.yes').toString()
    : i18n.t('switch.no').toString();
}

function getFormattedNumberValue(fieldValue: any): string {
  return String(fieldValue);
}

export function getFormattedFieldValue(
  field: FormField,
  stepContext: Record<string, any>,
  step: StepDefinition,
): string {
  const fieldValue = stepContext[field.name];

  if (field.outputFormatter && typeof field.outputFormatter === 'function') {
    return field.outputFormatter(fieldValue);
  }

  let outputString = '';

  switch (field.type) {
    case FormFieldType.TextField:
      outputString += getFormattedTextFieldValue(fieldValue);
      break;
    case FormFieldType.MultipleChoice:
      outputString += getFormattedMultipleChoiceValue(field, fieldValue, step);
      break;
    case FormFieldType.Switch:
      outputString += getFormattedSwitchValue(fieldValue);
      break;
    case FormFieldType.Number:
      outputString += getFormattedNumberValue(fieldValue);
      break;
    default:
      outputString += String(fieldValue);
      break;
  }

  if (field.outputFormatter !== 'value' && outputString.trim().length > 0) {
    outputString = `**${getStepFieldLabel(
      step.id,
      field.name,
    )}:** ${outputString}`;
  }

  return outputString;
}

export function getFormattedStepValues(
  context: AssistantContext,
  step: StepDefinition,
  repeatIndex?: number,
): string {
  const stepContext = getStepContext(context, step, repeatIndex).value;

  if (!stepContext) {
    return '';
  }

  if (step.outputFormatter) {
    return step.outputFormatter(stepContext, context);
  }

  if (!step.fields || step.fields.length === 0) {
    return '';
  }

  return step.fields
    ?.map(field => getFormattedFieldValue(field, stepContext, step))
    .filter(outputString => outputString.trim().length > 0)
    .join('<br />');
}

export function getFormattedStepGroupValues(
  context: AssistantContext,
  groupId: StepGroupId | string,
  steps: StepDefinition[],
) {
  const groupSteps = steps.filter(
    step => step.groupId && step.groupId === groupId,
  );
  const groupItemsCount = (context[groupId] as Array<any>).length || 0;

  return [...Array(groupItemsCount)].map((_, i) => {
    return groupSteps
      .map(step => {
        let outputString = '';

        if (groupSteps.length > 1) {
          outputString += `## ${getStepTitle(step.id)}  `;
        }

        outputString += getFormattedStepValues(context, step, i);

        return outputString;
      })
      .join('<br /><br />');
  });
}

export function getMailBody(
  steps: StepDefinition[],
  context: AssistantContext,
): any {
  const items: Array<{
    id: string | StepId | StepGroupId;
    type: 'step' | 'stepGroup';
  }> = [];

  steps
    .filter(step => step.fields && step.fields.length > 0)
    .filter(step =>
      step.visiblityCondition ? step.visiblityCondition(context) : true,
    )
    .forEach(step => {
      if (step.groupId) {
        if (
          items.findIndex(
            item => item.id === step.groupId && item.type === 'stepGroup',
          ) === -1
        ) {
          items.push({
            id: step.groupId,
            type: 'stepGroup',
          });
        }
      } else {
        if (
          items.findIndex(
            item => item.id === step.id && item.type === 'step',
          ) === -1
        ) {
          items.push({
            id: step.id,
            type: 'step',
          });
        }
      }
    });

  return items.map(item => {
    if (item.type === 'stepGroup') {
      const groupValues = getFormattedStepGroupValues(context, item.id, steps);
      return groupValues.map((groupValue, k) => ({
        label: i18n.t('groups.' + item.id) + ' ' + (k + 1),
        value: groupValue,
      }));
    } else {
      const step = getStep(item.id, steps);
      return {
        label: i18n.t(item.id + '.title'),
        value: step ? getFormattedStepValues(context, step) : '',
      };
    }
  });
}
