import { StepId } from '../../consts/assistant-steps';
import { FormFieldType } from '../../consts/input-types';
import {
  FormSelectConfig,
  StepDefinition,
} from '../../interfaces/step-definition';
import component from './Contact.vue';

const config: StepDefinition = {
  component,
  fields: [
    {
      config: {
        options: [
          {
            value: 'male',
          },
          {
            value: 'female',
          },
          {
            value: 'divers',
          },
        ],
      } as FormSelectConfig,
      name: 'gender',
      outputFormatter: 'value',
      required: true,
      type: FormFieldType.Select,
    },
    {
      name: 'firstname',
      outputFormatter: 'value',
      required: true,
      type: FormFieldType.TextField,
    },
    {
      name: 'lastname',
      outputFormatter: 'value',
      required: true,
      type: FormFieldType.TextField,
    },
    {
      name: 'phone',
      outputFormatter: 'value',
      required: true,
      type: FormFieldType.TextField,
    },
    {
      name: 'email',
      outputFormatter: 'value',
      required: true,
      type: FormFieldType.TextField,
    },
    {
      name: 'streetAddress',
      outputFormatter: 'value',
      required: true,
      type: FormFieldType.TextField,
    },
    {
      name: 'zipcode',
      outputFormatter: 'value',
      required: true,
      type: FormFieldType.TextField,
    },
    {
      name: 'city',
      outputFormatter: 'value',
      required: true,
      type: FormFieldType.TextField,
    },
    {
      default: true,
      name: 'sameInvoiceAddress',
      type: FormFieldType.Checkbox,
    },
    {
      config: {
        options: [
          {
            value: 'male',
          },
          {
            value: 'female',
          },
        ],
      } as FormSelectConfig,
      name: 'invoiceGender',
      outputFormatter: 'value',
      required: true,
      type: FormFieldType.Select,
    },
    {
      name: 'invoiceFirstname',
      outputFormatter: 'value',
      required: true,
      type: FormFieldType.TextField,
    },
    {
      name: 'invoiceLastname',
      outputFormatter: 'value',
      required: true,
      type: FormFieldType.TextField,
    },
    {
      name: 'invoiceStreetAddress',
      outputFormatter: 'value',
      required: true,
      type: FormFieldType.TextField,
    },
    {
      name: 'invoiceZipcode',
      outputFormatter: 'value',
      required: true,
      type: FormFieldType.TextField,
    },
    {
      name: 'invoiceCity',
      outputFormatter: 'value',
      required: true,
      type: FormFieldType.TextField,
    },
    {
      name: 'message',
      outputFormatter: 'value',
      type: FormFieldType.TextArea,
    },
  ],
  id: StepId.CONTACT,
  outputFormatter: (stepContext: any) => {
    let output = `${stepContext.gender} ${stepContext.firstname} ${stepContext.lastname}\n${stepContext.phone}\n${stepContext.email}`;

    if (stepContext.streetAddress != '') {
      output += `\n\nLieferadresse:\n${stepContext.streetAddress}\n${stepContext.zipcode} ${stepContext.city}\n`;
    }

    if (!stepContext.sameInvoiceAddress) {
      output += `\n\nRechnungsadresse:\n${stepContext.invoiceGender} ${stepContext.invoiceFirstname} ${stepContext.invoiceLastname}\n${stepContext.invoiceStreetAddress}\n${stepContext.invoiceZipcode} ${stepContext.invoiceCity}`;
    }

    if (stepContext.message && stepContext.message.trim().length > 0) {
      output += `\n\nMitteilung:\n${stepContext.message}`;
    }

    return output;
  },
};

export default config;
