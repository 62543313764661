













import { defineComponent } from '@vue/composition-api';
import GcButton from './primitives/GcButton.vue';
import GcDivider from './primitives/GcDivider.vue';

export default defineComponent({
  components: { GcButton, GcDivider },
  props: {
    title: {
      default: '',
      type: String,
    },
  },
  setup(_, { emit }) {
    function onCancel() {
      emit('cancel');
    }

    return {
      onCancel,
    };
  },
});
