














































































import GcForm from '../../components/primitives/GcForm.vue';
import GroupStepAlert from '../../components/GroupStepAlert.vue';
import AssistantStep from '../../components/AssistantStep.vue';
import AssistantStepNavigation from '../../components/AssistantStepNavigation.vue';
import { defineComponent, ref, watch } from '@vue/composition-api';
import { stepComponentProps } from '../../lib/steps/helper';
import { useBaseTransitions } from '../../lib/xstate/transitions';
import { getStepContext } from '../../lib/context';
import FormField from '../../components/FormField.vue';
import { StepId } from '../../consts/assistant-steps';

export default defineComponent({
  components: {
    AssistantStep,
    AssistantStepNavigation,
    FormField,
    GcForm,
    GroupStepAlert,
  },
  props: {
    ...stepComponentProps,
  },
  setup(props, { emit }) {
    const formData = getStepContext(props.assistantContext, props?.step!);
    const formValid = ref(false);
    const isSingleChargingPoint = !props.assistantContext[
      StepId.CONFIGURATION
    ].chargingPoints.includes('two');
    const showConnectionType = ref(isSingleChargingPoint);

    // Reset selected wallbox, needed to ensure nothing is selected
    // after back navigation. This can be improved by only resetting,
    // this if the configuration has changed
    props.assistantContext[StepId.WALLBOXES].wallbox = '';
    props.assistantContext[StepId.WALLBOXES].amount = 1;

    if (!isSingleChargingPoint) {
      formData.value.connectionType = [];
    }

    watch(
      () => formData.value.chargingPoints,
      value => {
        if (value.includes('two')) {
          formData.value.connectionType = [];
        }
        showConnectionType.value = !value.includes('two');
      },
    );

    return {
      formData,
      formValid,
      showConnectionType,
      ...useBaseTransitions(emit),
    };
  },
});
