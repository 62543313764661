import { StepId } from '../../consts/assistant-steps';
import { FormFieldType } from '../../consts/input-types';
import { StepDefinition } from '../../interfaces/step-definition';
import component from './Configuration.vue';

const config: StepDefinition = {
  component,
  fields: [
    {
      config: {
        columns: 2,
        disableUnselect: true,
        options: [
          {
            value: 'one',
          },
          {
            value: 'two',
          },
        ],
        singleAnswer: true,
        verticalOptionLayout: true,
      },
      name: 'chargingPoints',
      required: true,
      type: FormFieldType.MultipleChoice,
    },
    {
      config: {
        columns: 2,
        disableUnselect: true,
        options: [
          {
            value: 'yes',
          },
          {
            value: 'no',
          },
        ],
        singleAnswer: true,
        verticalOptionLayout: true,
      },
      name: 'paid',
      required: true,
      type: FormFieldType.MultipleChoice,
    },
    {
      config: {
        columns: 2,
        disableUnselect: true,
        options: [
          {
            value: 'yes',
          },
          {
            value: 'no',
          },
        ],
        singleAnswer: true,
        verticalOptionLayout: true,
      },
      name: 'smartphoneConnection',
      required: true,
      type: FormFieldType.MultipleChoice,
    },
    {
      config: {
        columns: 2,
        disableUnselect: true,
        options: [
          {
            value: '11',
          },
          {
            value: '22',
          },
        ],
        singleAnswer: true,
        verticalOptionLayout: true,
      },
      name: 'chargingPower',
      required: true,
      type: FormFieldType.MultipleChoice,
    },
    {
      config: {
        columns: 2,
        disableUnselect: true,
        options: [
          {
            value: 'socket',
          },
          {
            value: 'cable',
          },
        ],
        singleAnswer: true,
        verticalOptionLayout: true,
      },
      name: 'connectionType',
      required: true,
      type: FormFieldType.MultipleChoice,
    },
    {
      config: {
        columns: 2,
        disableUnselect: true,
        options: [
          {
            value: 'yes',
          },
          {
            value: 'no',
          },
        ],
        singleAnswer: true,
        verticalOptionLayout: true,
      },
      name: 'authorization',
      required: true,
      type: FormFieldType.MultipleChoice,
    },
  ],
  id: StepId.CONFIGURATION,
  visiblityCondition: context => {
    return (
      context[StepId.SITUATION].situation.length == 0 ||
      !context[StepId.SITUATION].situation.includes('none')
    );
  },
};

export default config;
