import { UnwrapRef } from '@vue/composition-api';
import {
  AnyEventObject,
  StatesConfig,
  TransitionConfig,
  TransitionsConfig,
} from 'xstate';
import { AssistantContext } from '../../config/context';
import {
  JUMP_TO_CONTACTFORM,
  NEXT,
  PREV,
} from '../../consts/assistant-state-events';
import { StepDefinition } from '../../interfaces/step-definition';

export const buildStates = (
  steps: Array<StepDefinition>,
  context: AssistantContext,
): StatesConfig<AssistantContext, any, AnyEventObject> => {
  const stateMap: StatesConfig<AssistantContext, any, AnyEventObject> = {};

  steps.forEach((step: StepDefinition, index) => {
    let transitions: TransitionsConfig<AssistantContext, any> = {};

    if (index > 0 && (index < steps.length - 1 || !step.isFinal)) {
      transitions = {
        ...transitions,
        [PREV]: { target: steps[index - 1].id },
      };
    }
    if (index < steps.length - 1) {
      const nextTransition: TransitionConfig<AssistantContext, any>[] = [];

      let nextIndex = index + 1;
      while (steps[nextIndex] !== undefined) {
        const visibilityCondition = steps[nextIndex].visiblityCondition;
        if (!visibilityCondition) {
          nextTransition.push({ target: steps[nextIndex].id });
          break;
        }

        nextTransition.push({
          cond: () => {
            return visibilityCondition(context);
          },
          target: steps[nextIndex].id,
        });

        nextIndex++;
      }

      transitions = {
        ...transitions,
        [NEXT]: nextTransition,
      };
    }

    const contactFormStepIndex = steps.findIndex(s => s.isContactForm);

    if (contactFormStepIndex !== -1 && index < contactFormStepIndex) {
      const contactFormStep = steps[contactFormStepIndex];
      transitions = {
        ...transitions,
        [JUMP_TO_CONTACTFORM]: { target: contactFormStep.id },
      };
    }

    // enable go back to previous steps
    if (!step.isFinal) {
      steps
        .filter(s => s !== step)
        .slice(0, index)
        .forEach(s => {
          if (s) {
            transitions = {
              ...transitions,
              ['BACK_TO_' + s.id.toUpperCase()]: {
                target: s.id,
              },
            };
          }
        });
    }

    stateMap[step.id] = {
      on: { ...transitions, ...step.transitions },
    };

    if (step.isFinal) {
      stateMap[step.id].type = 'final';
    }
  });
  return stateMap;
};
