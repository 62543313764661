import { StepId } from '../../consts/assistant-steps';
import { FormFieldType } from '../../consts/input-types';
import { StepDefinition } from '../../interfaces/step-definition';
import component from './Situation.vue';

const config: StepDefinition = {
  component,
  fields: [
    {
      config: {
        disableUnselect: true,
        options: [
          {
            value: 'own',
          },
          {
            value: 'rented',
          },
          {
            value: 'none',
          },
        ],
        singleAnswer: true,
      },
      name: 'situation',
      outputFormatter: 'value',
      required: true,
      type: FormFieldType.MultipleChoice,
    },
  ],
  id: StepId.SITUATION,
};

export default config;
