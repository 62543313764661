


































import GcForm from '../../components/primitives/GcForm.vue';
import GroupStepAlert from '../../components/GroupStepAlert.vue';
import AssistantStep from '../../components/AssistantStep.vue';
import AssistantStepNavigation from '../../components/AssistantStepNavigation.vue';
import WallboxSelect from '../../components/WallboxSelect.vue';
import AccessorySelect from '../../components/AccessorySelect.vue';
import { computed, defineComponent, provide, ref } from '@vue/composition-api';
import { stepComponentProps } from '../../lib/steps/helper';
import { useBaseTransitions } from '../../lib/xstate/transitions';
import { getStepContext } from '../../lib/context';
import FormField from '../../components/FormField.vue';
import { StepId } from '../../consts/assistant-steps';
import { getValidators } from '../../lib/forms/validators';

export default defineComponent({
  components: {
    AssistantStep,
    AssistantStepNavigation,
    FormField,
    GcForm,
    GroupStepAlert,
    WallboxSelect,
    AccessorySelect,
  },
  props: {
    ...stepComponentProps,
  },
  setup(props, { emit }) {
    const formData = getStepContext(props.assistantContext, props.step!);
    const formValid = ref(false);

    const wallboxFilter = computed(() => {
      return {
        ...props.assistantContext[StepId.CONFIGURATION],
      };
    });

    const wallboxValidators = computed(() => {
      const wallboxField = props.step?.fields?.find(
        field => field.name === 'wallbox',
      );
      return wallboxField ? getValidators(wallboxField) : [];
    });

    const onInput = input => {
      // reset values on deselect
      if (!input) {
        formData.value.amount = 1;
        formData.value.wallbox = '';
      }
    };

    const isCustomer = computed(
      () =>
        props.assistantContext[StepId.CUSTOMER].alreadyCustomer[0] === 'yes',
    );

    provide('wallboxFilter', wallboxFilter);

    return {
      formData,
      formValid,
      wallboxValidators,
      isCustomer,
      onInput,
      ...useBaseTransitions(emit),
    };
  },
});
