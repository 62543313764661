
























































































































































































import GcForm from '../../components/primitives/GcForm.vue';
import GcAlert from '../../components/primitives/GcAlert.vue';
import GcCol from '../../components/primitives/GcCol.vue';
import GcRow from '../../components/primitives/GcRow.vue';
import GroupStepAlert from '../../components/GroupStepAlert.vue';
import AssistantStep from '../../components/AssistantStep.vue';
import AssistantStepNavigation from '../../components/AssistantStepNavigation.vue';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { stepComponentProps } from '../../lib/steps/helper';
import { useBaseTransitions } from '../../lib/xstate/transitions';
import { getStepContext } from '../../lib/context';
import FormField from '../../components/FormField.vue';
import { StepId } from '../../consts/assistant-steps';

export default defineComponent({
  components: {
    AssistantStep,
    AssistantStepNavigation,
    FormField,
    GcAlert,
    GcCol,
    GcForm,
    GcRow,
    GroupStepAlert,
  },
  props: {
    ...stepComponentProps,
  },
  setup(props, { emit }) {
    const formData = getStepContext(props.assistantContext, props.step!);
    const formValid = ref(false);

    const isOrder = computed(() => {
      const walboxSelected =
        props.assistantContext[StepId.WALLBOXES].wallbox.trim() !== '';
      const installationSelected = props.assistantContext[
        StepId.INTEREST
      ].interest.includes('installation');

      return walboxSelected || installationSelected;
    });

    const invalidSituation = computed(() => {
      const situation = props.assistantContext?.[StepId.SITUATION].situation;
      return situation && situation.includes('none');
    });

    return {
      formData,
      formValid,
      ...useBaseTransitions(emit),
      isOrder,
      invalidSituation,
    };
  },
});
