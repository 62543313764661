import { StepId } from '../../consts/assistant-steps';
import { FormFieldType } from '../../consts/input-types';
import { StepDefinition } from '../../interfaces/step-definition';
import { useLisOfferData } from '../../lib/data/wallboxes';
import i18n from '../../plugins/i18n';
import component from './Wallboxes.vue';
import { CONTACT_OPTION_ID } from '../../components/AccessorySelect.vue';

const config: StepDefinition = {
  component,
  fields: [
    {
      name: 'wallbox',
      type: FormFieldType.CustomSelect,
    },
    {
      config: {
        max: 5,
        min: 1,
      },
      default: '1',
      name: 'amount',
      required: true,
      type: FormFieldType.Number,
    },
    {
      name: 'accessory',
      type: FormFieldType.CustomSelect,
    },
  ],
  id: StepId.WALLBOXES,
  outputFormatter: (stepContext, context) => {
    const { wallboxOffers } = useLisOfferData();
    let wallboxName = '';

    if (stepContext.wallbox.trim() !== '') {
      const selectedWallbox = wallboxOffers.value?.find(
        wallbox => wallbox._id === stepContext.wallbox.trim(),
      );

      wallboxName = selectedWallbox
        ? `${selectedWallbox._reference.manufacturer} ${selectedWallbox._reference.name}`
        : '';
    }

    let output = '';
    const accessories = stepContext.accessory;

    if (wallboxName === '' || accessories[0]?._id == CONTACT_OPTION_ID) {
      output = `${i18n.t(`${StepId.WALLBOXES}.consultationNecessary`)}`;
    } else if (wallboxName !== '') {
      output += `${stepContext.amount}x ${wallboxName}`;
      for (const accessory of stepContext.accessory) {
        if (accessory._id === CONTACT_OPTION_ID) {
          output += `${i18n.t(`${StepId.WALLBOXES}.consultationNecessary`)}`;
        } else {
          output += `<br>1x ${accessory._reference.name}`;
        }
      }
    }

    return output;
  },
};

export default config;
