

































































































































import { computed, defineComponent, ref } from '@vue/composition-api';
import GcForm from '../../components/primitives/GcForm.vue';
import GcCol from '../../components/primitives/GcCol.vue';
import GcRow from '../../components/primitives/GcRow.vue';
import FormField from '../../components/FormField.vue';
import GcButton from '../../components/primitives/GcButton.vue';
import OverviewListItem from '../../components/OverviewListItem.vue';
import AssistantStep from '../../components/AssistantStep.vue';
import AssistantStepNavigation from '../../components/AssistantStepNavigation.vue';
import { stepComponentProps } from '../../lib/steps/helper';
import { StepId } from '../../consts/assistant-steps';
import { useBaseTransitions } from '../../lib/xstate/transitions';
import { useLisOfferData } from '../../lib/data/wallboxes';
import { isInMfnGridArea } from '../../lib/data/location';
import { DELIVERY_FEE } from '../../lib/data/prices';
import { getStepContext } from '../../lib/context';
import md from '../../lib/formatter/markdown';
import { formatPrice, getNetPrice, getTax } from '../../lib/formatter/price';
import { getFormattedStepValues } from '../../lib/formatter/context';
import { sendMail } from '../../lib/submit/send-mail';
import i18n from '../../plugins/i18n';
import { AssistantContext } from '../../config/context';
import { Inquiry } from '../../consts/inquiry-types';

export default defineComponent({
  components: {
    AssistantStep,
    AssistantStepNavigation,
    FormField,
    GcButton,
    GcCol,
    GcForm,
    GcRow,
    OverviewListItem,
  },
  props: {
    ...stepComponentProps,
  },
  setup(props, { emit }) {
    const { onNext, onPrev } = useBaseTransitions(emit);
    const submitting = ref(false);
    const submitError = ref<boolean | string>(false);
    const formData = getStepContext(props.assistantContext, props.step!);
    const formValid = ref(false);

    const overviewItems = computed(() => {
      return [
        StepId.CUSTOMER,
        StepId.CLIENT,
        StepId.INTEREST,
        StepId.SITUATION,
        StepId.CONFIGURATION,
        StepId.WALLBOXES,
        StepId.CONTACT,
      ]
        .map(stepId => {
          const stepDef = props.steps?.find(step => step.id === stepId);
          return {
            content: stepDef
              ? getFormattedStepValues(props.assistantContext, stepDef)
              : '',
            stepId,
          };
        })
        .filter(item => {
          const { stepId } = item;
          const stepDef = props.steps?.find(step => step.id === stepId);
          return stepDef?.visiblityCondition
            ? stepDef.visiblityCondition(props.assistantContext)
            : true;
        })
        .filter(item => item.content.trim().length > 0);
    });

    const deliveryFee = computed(() => {
      const wallboxStep = props.assistantContext[StepId.WALLBOXES];
      const contactStep = props.assistantContext[StepId.CONTACT];

      if (!wallboxStep.wallbox) {
        return 0;
      }

      if (isInMfnGridArea(contactStep.zipcode)) {
        return 0;
      }

      return DELIVERY_FEE;
    });

    const order = computed(() => {
      const { wallboxOffers } = useLisOfferData();
      const wallboxStep = props.assistantContext[StepId.WALLBOXES];
      const customerStep = props.assistantContext[StepId.CUSTOMER];
      const wallboxId = wallboxStep.wallbox;
      let totalPrice = 0;
      let orderPrice = 0;

      const selectedWallbox = wallboxOffers.value?.find(
        wallbox => wallbox._id === wallboxId,
      );

      const accessories = wallboxStep.accessory;

      const wallboxName = selectedWallbox
        ? `${selectedWallbox._reference.manufacturer} ${selectedWallbox._reference.name}`
        : '';

      if (
        selectedWallbox?.priceReduced &&
        customerStep.alreadyCustomer[0] === 'yes'
      ) {
        orderPrice += wallboxStep.amount * selectedWallbox.priceReduced;
      } else if (selectedWallbox?.price) {
        orderPrice += wallboxStep.amount * selectedWallbox.price;
      }

      if (selectedWallbox && accessories.length > 0) {
        accessories.forEach(accessory => {
          orderPrice += accessory.price;
        });
      }

      totalPrice = orderPrice;

      if (deliveryFee.value) {
        totalPrice += deliveryFee.value;
      }

      return {
        ...wallboxStep,
        deliveryFee: deliveryFee.value,
        orderPrice,
        totalPrice,
        wallbox: {
          ...selectedWallbox,
          amount: wallboxStep.amount,
          name: wallboxName,
          price:
            selectedWallbox?.priceReduced &&
            customerStep.alreadyCustomer[0] === 'yes'
              ? selectedWallbox.priceReduced
              : selectedWallbox?.price || 0,
        },
        accessories,
      };
    });

    function editStep(stepId: string) {
      emit('transition', {
        event: `BACK_TO_${stepId.toUpperCase()}`,
      });
    }

    const processContext = (context: AssistantContext) => {
      const processedContext: AssistantContext = { ...context };

      const contact = context[StepId.CONTACT];

      // Remove installation from final order confirmation
      if (!isInMfnGridArea(contact.zipcode)) {
        processedContext[StepId.WALLBOXES].installation = false;
      }

      return processedContext;
    };

    async function onSubmit() {
      submitError.value = false;
      submitting.value = true;
      try {
        const processedContext = processContext(props.assistantContext);

        const orderContext = {
          inquiryType:
            order.value.totalPrice > 0 ? Inquiry.order : Inquiry.consultation,
          order: order.value,
        };

        await sendMail(props.steps!, processedContext, orderContext);
        onNext();
      } catch (e) {
        submitError.value = e.message || i18n.t('message.error').toString();
      } finally {
        submitting.value = false;
      }
    }

    return {
      deliveryFee,
      editStep,
      formData,
      formValid,
      formatPrice,
      getNetPrice,
      getTax,
      md,
      onPrev,
      onSubmit,
      order,
      overviewItems,
      submitError,
      submitting,
    };
  },
});
