const TAX = 0.19;

export function formatPrice(price: number) {
  return new Intl.NumberFormat('de-DE', {
    currency: 'EUR',
    style: 'currency',
  }).format(price);
}

export const getTax = (price: number) => formatPrice(price * TAX / (1 + TAX));

export const getNetPrice = (price: number) => formatPrice(price / (1 + TAX));
